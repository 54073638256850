<template>
  <div v-if="permission.access">
    <b-card no-body>
      <b-card-body>
        <b-card-title class="d-flex justify-content-between">
          <div
            class="form-group d-flex align-items-center justify-content-start mb-md-0"
          >
            <label class="mr-1">Show </label>
            <Select
              style="margin-right: 10px"
              @on-change="paginateChange"
              v-model="search.paginate"
              :clearable="false"
            >
              <Option :value="10"> 10 </Option>
              <Option :value="30"> 30 </Option>
              <Option :value="50"> 50 </Option>
              <Option :value="80"> 80 </Option>
              <Option :value="100"> 100 </Option>
            </Select>
          </div>
        </b-card-title>
        <div class="row">
          <div class="col-xl-2" v-for="(item, index) in selected" :key="index">
            <div class="profile-wrapper center">
              <div class="profile-body mt-2 d-flex justify-content-between">
                <div class="IMAGE">
                  <img
                    class="d-block"
                    :src="item.photo || getImage(60, 60)"
                    alt="dd"
                    width="60"
                    height="60"
                  />
                </div>

                <div class="profile-details">
                  <h5 style="margin-top: 15px; font-size: 12px; color: black">
                    {{ item.name }}
                  </h5>
                  <p class="description" style="line-height: 16px">
                    {{ item.designation }}
                  </p>
                </div>
                <p
                  style="margin-top: 10px; margin-right: 15px; cursor: pointer"
                  @click="removeSelectedItem(index)"
                >
                  X
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-2">
            <b-button
              variant="primary"
              type="submit"
              @click="Propose(1)"
              :disabled="form.busy"
              class="ml-1 mt-3"
              v-if="selected.length"
            >
              Propose
            </b-button>
          </div>
        </div>
      </b-card-body>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th class="text-center">Sl</th>
              <th class="px-5">Photo</th>
              <th>Candidate Info</th>
              <th>DateTime</th>
              <th>Preference</th>
              <th>Skill</th>
              <th class="text-center">Status</th>
              <th class="text-center">Action</th>
            </tr>
          </thead>
          <tbody v-if="candidates.data.length">
            <tr v-for="(candidate, index) in candidates.data" :key="index">
              <td class="align-middle text-center">
                {{ candidates.meta.from + index }}
              </td>
              <td class="align-middle text-center">
                <b
                  ><img
                    style="border-radius: 50%"
                    :src="candidate.photo"
                    alt="photo"
                    width="70"
                /></b>
              </td>
              <td>
                <p><b>Name :</b> {{ candidate.name }}</p>
                <p><b>Email :</b> {{ candidate.email }}</p>
                <p><b>Phone :</b> {{ candidate.phone }}</p>
              </td>
              <td>
                <p v-if="candidate.email_verified_at != null">
                  <b>Verified At :</b> {{ candidate.email_verified_at }}
                </p>
                <p v-else>Not verified yet</p>
                <p><b>Created At :</b> {{ candidate.created_at }}</p>
              </td>
              <td v-if="candidate.preference">
                <p>
                  <b>Designation :</b>
                  {{ candidate.preference.designation }}
                </p>
                <p><b>Seniority :</b> {{ candidate.preference.seniority }}</p>
              </td>
              <td v-else>No preference Yet</td>
              <td
                width="20%"
                v-if="candidate.skills && candidate.skills.length"
              >
                <span
                  v-for="(skill, index) in candidate.skills"
                  :key="index"
                  style="margin-right: 2px; margin-bottom: 2px"
                  :class="{
                    'badge badge-secondary': skill.proficiency === 'BEGINNER',
                    'badge badge-primary': skill.proficiency === 'EXPERIENCED',
                    'badge badge-info': skill.proficiency === 'ADVANCED',
                    'badge badge-success': skill.proficiency === 'EXPERT',
                  }"
                >
                  {{ skill.name + " (" + skill.proficiency + ") " }}
                </span>
              </td>
              <td v-else>No skills Yet</td>
              <td class="align-middle text-center">
                <span
                  style="margin-right: 2px"
                  :class="
                    candidate.onboard_status !== 'Onboad Not Complete'
                      ? 'badge badge-primary'
                      : 'badge badge-danger'
                  "
                >
                  {{ candidate.onboard_status }}
                </span>

                <span
                  :class="
                    candidate.status !== 'inactive'
                      ? 'badge badge-primary'
                      : 'badge badge-danger'
                  "
                >
                  {{ candidate.status }}
                </span>
              </td>
              <td class="text-center">
                <router-link
                  class="btn btn-primary btn-sm"
                  :to="'/profile/' + candidate.id"
                >
                  <feather-icon icon="EyeIcon" />
                </router-link>
                <button
                  class="btn btn-sm btn-success"
                  style="margin-left: 4px; font-size: 14px"
                  @click="
                    info(
                      candidate.id,
                      candidate.name,
                      candidate.photo,
                      candidate.preference.designation,
                      candidate.status
                    )
                  "
                  :disabled="isButtonDisabled(candidate.id)"
                >
                  <feather-icon icon="CheckIcon" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-card-body>
        <b-row>
          <b-col cols="6">
            <span class="text-muted"
              >Showing {{ candidates.meta.from }} to {{ candidates.meta.to }} of
              {{ candidates.meta.total }} entries</span
            >
          </b-col>
          <b-col cols="6">
            <pagination
              class="mb-0"
              :data="candidates"
              @pagination-change-page="getResults"
              align="right"
              :show-disabled="true"
              :limit="5"
            ></pagination>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BMedia,
  BRow,
  BCol,
  BLink,
  BCardText,
  BCardTitle,
  BImg,
  BButton,
  BPagination,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  data() {
    return {
      id: this.$route.params.id,
      form: new Form({
        id: [],
        status: null,
      }),
      selected: [],
      search: {
        paginate: 10,
        page: 1,
      },
      candidates: [],
    };
  },
  components: {
    BCard,
    BCardBody,
    BCardText,
    BLink,
    BMedia,
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    BPagination,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
        .get(`/app/company/job/match/candidate/` + this.id + queryParams)
        .then((res) => {
          this.candidates = res.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    Propose(status) {
      this.form.id = [];
      this.selected.forEach((item) => {
        this.form.id.push(item.id);
      });
      this.form.status = status;
      this.form
        .post("/app/company/job/propose/" + this.id)
        .then((res) => {
          this.s(res.data.message);
          this.selected = [];
          this.getResults();
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    isButtonDisabled(candidateId) {
      return this.selected.some((item) => item.id === candidateId);
    },

    removeSelectedItem(index) {
      this.selected.splice(index, 1);
    },

    info(id, name, photo, designation, status) {
      const isDuplicate = this.selected.some((item) => item.id === id);
      if (!isDuplicate) {
        this.selected.push({ id, name, photo, designation, status });
      }
    },

    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.jobs;
    },
  },
};
</script>
<style scoped>
table td {
  padding: 0.72rem 1rem;
}
.table th {
  padding: 0.72rem 1rem;
}
table td p {
  margin-bottom: 3px;
}

.profile-body {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 20px;
}

.profile-body img {
  margin: 10px 15px;
  border: 3px solid white;
  border-radius: 25%;
}
.description {
  font-size: 12px;
}
.profile-details h5 {
  margin-bottom: 0.1em;
}
</style>
